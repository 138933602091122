import React, { useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';

import Swedish from './Swedish';
import English from './English';


const RedirectUrl = ({ url }) => {
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return <h5>Redirecting...</h5>;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes> 
        {/*<Route path="/" element={<Swedish />} />*/}
        <Route path="/" element={<RedirectUrl url="https://drive.google.com/drive/folders/1CHxp1GNdd-c57BOoN1GlhNJoaD65i_Zz" />} />
        <Route path="/english" element={<English />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
